import {FileInputComponent, FormInputComponentDecorator} from '.';

export function FormInputComponent({input, initialized, error, controller, uniqueName, label, inputType, initialValue, fieldObject}) {

    function isBlank(variable) {
        return(variable == null  || typeof(variable) == 'undefined' || variable === '');
    }

    if( (!isBlank(initialValue)) && isBlank(input[uniqueName]) && !initialized[uniqueName] ) {
        input[uniqueName] = initialValue;
        initialized[uniqueName] = true;
    }

    const opts = {};
    if(fieldObject.helpText) opts['aria-describedby'] = uniqueName + 'Help';

    if( inputType === 'hidden' ) 
        return <input 
            type={inputType}             
            name={uniqueName} 
            value={input[uniqueName]}             
        />;
    else if ( inputType === 'checkbox' ) {        
        return (
            <div className="form-check mb-3">
                <FormInputComponentDecorator error={error} fieldObject={fieldObject}>
                    <input 
                        type={inputType} 
                        className="form-check-input" 
                        name={uniqueName} 
                        value={input[uniqueName]}              
                        checked={input[uniqueName] ? true : false}           
                        onChange={controller.handleChange} 
                        onBlur={controller.validateInput} {...opts}
                    />
                    <label className="form-check-label" htmlFor="{uniqueName}">{label}</label>
                </FormInputComponentDecorator>  
            </div>
        )     
    }
    else return (        
        <div className={'mb-3' + (error[uniqueName] ? ' has-error' : '')}>
            <label htmlFor={uniqueName}>{label}</label>
            {(() => {                
                switch(inputType) {
                    case 'textarea':
                        return <FormInputComponentDecorator error={error} fieldObject={fieldObject}>
                            <textarea 
                                className={'form-control ' + (error[uniqueName] ? 'is-invalid' : '')} 
                                name={uniqueName} 
                                onChange={controller.handleChange} 
                                onBlur={controller.validateInput} {...opts}
                            >{input[uniqueName]}</textarea>
                        </FormInputComponentDecorator>
                    case 'file':   
                        if(fieldObject.accept) opts['accept'] = fieldObject.accept;
                        return <FileInputComponent                             
                            error={error} 
                            controller={controller}                             
                            fieldObject={fieldObject} {...opts}
                        />         
                    case 'checkbox': 
                         return <FormInputComponentDecorator error={error} fieldObject={fieldObject}>
                                <input 
                                    type={inputType} 
                                    className="form-check-input" 
                                    name={uniqueName} 
                                    value={input[uniqueName]} 
                                    onChange={controller.handleChange}
                                    onBlur={controller.validateInput} {...opts}
                                />
                                <label class="form-check-label" for="{uniqueName}">{label}</label>
                            </FormInputComponentDecorator>     
                    default:
                        return <FormInputComponentDecorator error={error} fieldObject={fieldObject}>
                            <input 
                                type={inputType} 
                                className={'form-control ' + (error[uniqueName] ? 'is-invalid' : '')} 
                                name={uniqueName} 
                                value={input[uniqueName]} 
                                onChange={controller.handleChange} 
                                onBlur={controller.validateInput} {...opts}
                            />
                        </FormInputComponentDecorator>
                }
            })()}                    
        </div>
    );
}