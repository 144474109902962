export default class FormComponentController {

    constructor(input, setInput, setLoading, error, setError, setErrorMessage, navigate, fields) {
        this.input = input;
        this.setInput = setInput;
        this.setLoading = setLoading;
        this.error = error;
        this.setError = setError;
        this.setErrorMessage = setErrorMessage;
        this.fields = fields;
        this.navigate = navigate;
    }

    handleBlur = (e) => {
      const { name } = e.target;        
      const isFile = e.target.type === 'file';
      
      // we don't need need to re-validate for files
      if( isFile && this.error.hasOwnProperty(name) ) return;

      const errors = this.validateInput(e);      
      if( isFile && errors.hasOwnProperty(name) ) {        
        // we need to erase previous value
        this.setInput(prev => ({
          ...prev,
          [name]: ''
        }));
      }      
    }

    handleSubmit = (e, _handleSubmit, navigateToOnComplete) => {
      e.preventDefault();

      if( !this._isFormValid() ) return;
              
      this.setLoading(true);

      const submissionPromise = _handleSubmit(this.input);
      submissionPromise.then((error) => {
        if(error) {
          this.setErrorMessage( error );
          this.setLoading(false);
        } else {
          this.setLoading(false);     
          if(navigateToOnComplete) {
            this.navigate(navigateToOnComplete);
          } 
        }
       }).catch( error => {
          this.setErrorMessage( error );
          this.setLoading(false);
       });
    }

    
    _handleFile = ( name, file ) => {
      
      this.setLoading(true);

      const fileReader = new FileReader();  
      fileReader.onloadend = (e) => {            
        this.setLoading(false);
        let content = fileReader.result;

        let field = this._findFieldByName( name );
        if( field.extractor && typeof field.extractor === 'function' ) {
          try {
            content = field.extractor( content );
          } catch (e) {
            this.setError(prev => {
              return {  ...prev, [name]: e.message }
            }); 
            return;
          }          
        }

        this.setInput(prev => ({
          ...prev,
          [name]: btoa( content )
        }));
      }
      
      fileReader.onerror = (e) => {
        console.log( 'file reading error occurred', e );
        this.setLoading(false);
      }
      fileReader.onabort = (e) => {
        this.setLoading(false);
      }

      fileReader.readAsText( file );   
    }
    
    handleChange = (e) => {      

        const { name, value } = e.target;    

        // loading file contents from the local file into browser, the content is not available immidiately
        if( e.target.type === 'file' && e.target.files[0] !== undefined ) {          
          const errors = this.validateInput(e);
          if ( errors.hasOwnProperty( name ) ) {
            return;
          }
          this._handleFile( name, e.target.files[0] );                 
        }
        else {        
          const isCheckbox = e.target.type === 'checkbox';
          
          this.setInput(prev => ({
            ...prev,
            [name]: isCheckbox ? e.target.checked : value
          }));
          this.validateInput(e);
        }
    }

    validateInput = (e) => {               
        const { name, value, files } = e.target;
        const polymorphicValue = ( files && files[0] ) || value;
        
        const validatedState = this._validateInput( name, polymorphicValue );        
        this.setError(prev => {
            const stateObj = {  ...prev, [name]: "" };
            delete stateObj[name]; // helps us to determine if there are any errors            
            return { ...stateObj, ...validatedState }            
        });   
        return validatedState;     
    }

    _isFormValid = () => {        
        let combinedState = {};

        for (let key in this.input) {         
            const newErrorState = this.error.hasOwnProperty( key ) 
              ? { [key]: this.error[key] }
              : this._validateInput( key, this.input[key] );
            combinedState = { ...combinedState, ...newErrorState };
        }       

        this.setError(prev => {
            return {  ...prev, ...combinedState }
        });

        return Object.keys(combinedState).length === 0;
    }

    _findFieldByName = ( name ) => {
      return this.fields.filter((field) => {
        return field.uniqueName === name;
      })[0];
    }

    _validateInput = ( name, value ) => {
        let field = this._findFieldByName(name);
        const stateObj = {};

        if( field.isRequired && ( ( typeof value === 'object' && value.length === 0 ) || !value ) ){
          stateObj[name] = `Please enter ${field.label}`
          return stateObj;
        }

        if(field.validators) {          

          for(let currentValidatorFunction of field.validators) {
            currentValidatorFunction = currentValidatorFunction.bind(FormComponentController);
            let error = currentValidatorFunction(name, value, this.input);
            if (error) {
              stateObj[name] = error;
            }
          }
        }
        return stateObj;
    }
}