import { useState } from 'react';
import config from '../../config';
import { authHeader } from '../../Helpers/auth-header';
import { Loading } from '../../Components';
import { toast } from "react-toastify";

export function ConnectionStatusCardComponent({direction}) {
    const [status, setStatus] = useState('Unknown'); 
    const [organizationId, setOrganizationId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    if(!organizationId) {
        setOrganizationId(JSON?.parse(window.sessionStorage.getItem('user'))?.id);
    }

    const testConnection = () => {
        setIsLoading(true);
        fetch(
            `${config.apiUrl}/api/connections/?direction=${direction}`, 
            {method: 'GET', headers: { 'Accept': 'application/json', ...authHeader() }}
        )
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            if(!response?.connection) {
                setStatus('Not configured');
                return;
            }
            let connectionConfig = response.connection.config;
            let body = {
                type: response.connection.type,
                connection: {...connectionConfig}
            };
    
            const testRequestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', ...authHeader() },
                body: JSON.stringify(body)
            };
            return fetch(`${config.apiUrl}/api/connections/test`, testRequestOptions );
        })
        .then((response) => {

            if(!response) {
                return;
            }

            if(response.ok) {
                toast.success('Connected successfully!');
                setStatus('Connected');
            } else {
                toast.warning('Unable to connect.');
                setStatus('Disconnected');
            }
        })
        .catch((error) => {
            console.log('error stack: ', error.stack);
            toast.error(
                `Unknown error.
                Please provide the following information to Arcane Support: ${error.message}`,
                {autoClose: false})
            ;
            setStatus('Unknown Error: ' + error.message);
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }

    let title = direction.charAt(0).toUpperCase() + direction.slice(1) + " Connection Status";
    return (<>
        {isLoading && <Loading/>}
        <div className="col-md-6 col-md-offset-3">
            <div className="card neumorphism-card">
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <div className="card-columns">
                        <button type="button" onClick={testConnection}>Test Connection</button>
                        <div className="neumorphism-inset neumorphism-card-status"><b>Status:&nbsp;</b><div className={status === 'Unknown' ? 'text-muted' : (status === 'Connected' ? 'text-success' : 'text-danger')}>{status}</div></div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}