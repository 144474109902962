import { useState, useEffect } from 'react';
import config from '../../config';
import { authHeader } from '../../Helpers/auth-header';

const delay = ms => new Promise(res => setTimeout(res, ms));

const STATUS_UNKNOWN = '...';
const STATUS_IN_PROGRESS = 'In Progress';
const STATUS_COMPLETE = 'Complete';

export function ProvisioningStatusCardComponent() {
    const [status, setStatus] = useState(STATUS_UNKNOWN); 
    const [organizationId, setOrganizationId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    if(!organizationId) {
        setOrganizationId(JSON?.parse(window.sessionStorage.getItem('user'))?.id);
    }

    async function onLoad( ) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Accept': 'application/json', ...authHeader() }
        };
        const response = await fetch(`${config.apiUrl}/api/microservice/domainProvisioningStatus`, requestOptions );
        const responseJson = await response.json();
        if( responseJson.domainProvisioningStatus === true ) {
            setStatus(STATUS_COMPLETE);
        } else {
            setStatus(STATUS_IN_PROGRESS);
        }
    }

    useEffect(() => {      
        onLoad();
    }, []);  

    return (<>
        <div className="col-md-6 col-md-offset-3">
            <div className="card neumorphism-card">
                <div className="card-body">
                    <h5 className="card-title">Provisioning Status</h5>
                    <div className="neumorphism-inset neumorphism-card-status"><div className={status === STATUS_COMPLETE ? 'text-success' : 'text-muted'}>{status}</div></div>
                </div>
            </div>
        </div>
    </>);
}