import { Outlet, NavLink } from "react-router-dom";

export function ProfilePage() {
    return (
        <>
            <h1>Your Profile</h1>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <NavLink to="/profile/configureSource" className="nav-link">Configure Source Connection</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/profile/configureTarget" className="nav-link">Configure Target Connection</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/profile/dashboard" className="nav-link">Dashboard</NavLink>
                </li>            
            </ul>
            <Outlet />
        </>        
    );
}